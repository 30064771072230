import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { HttpRequest } from "../../utils/common.js"
import BaseButton from "../../components/Common/Buttons/BaseButton";
import Radio from "../../components/Common/Radio"
import Layout from "../../components/Common/Layout";

type Props = {
  token: string;
};

const Index: React.FC<Props> = (props) => {
  interface IFormInput {
    is_soccer_pro_league: string;
    twitter_followers: string;
    instagram_followers: string;
    facebook_followers: string;
    company_name: string;
    name: string;
    email: string;
    privacy: boolean;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const formRef = useRef(null);
  const { token } = props;

  const onSubmit = (data) => {
    HttpRequest.post("/simulator",
      {
        company_name: data.company_name,
        email: data.email,
        facebook_followers: data.facebook_followers,
        instagram_followers: data.instagram_followers,
        twitter_followers: data.twitter_followers,
        is_soccer_pro_league: data.is_soccer_pro_league,
        name: data.name
      })
      .then((res) => {
        location.href = `/simulator/result/${res.data.id}`
      })
      .catch((e) => {
        console.error(e);
      });
    };

  return (
    <Layout
      breadcrumbs={[
        {
          text: "HOME",
          href: "/",
        },
        {
          text: "支援金額シミュレーション",
        },
      ]}
    >
      <section className="mt-[40px] px-[30px] sm:max-w-main sm:mb-[40px] sm:mt-[60px] sm:mx-auto md:px-0">
        <h2 className="mb-[40px] sm:mb-[60px] text-lg font-bold sm:text-2xl">支援金額シミュレーション</h2>
        <div className="sm:w-[700px] sm:mx-auto">
          <div className="mb-10 text-sm">
            <p>
              SNSのフォロワー数などを元に、過去スポチュニティで実施したクラウドファンディングから想定支援金額をシミュレーションします。
            </p>
            <p>
              ご入力いただいた情報を元にご連絡させて頂く場合がございますのでご了承ください。
            </p>
          </div>
          <form
            className=""
            method="POST"
            action="/simulator"
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
          >
            <input type="hidden" name="authenticity_token" value={token} />
            <div className="mb-[60px]">
              <p className="mb-5 pl-2 sm:text-xl font-bold border-l-4 border-[#006AC9]">
                入力項目
              </p>
              <div className="mb-10">
                <p className="mb-1">
                  Jリーグのクラブかどうかによってシミュレーション結果が変わってくるため、Jリーグのクラブかどうかを教えてください。<br />これからシミュレーションする対象はJクラブですか？
                </p>
                <div className="flex">
                  <div className="mr-5">
                    <Radio
                      label="はい"
                      id="simulator_is_soccer_pro_league_true"
                      value="true"
                      defaultChecked={false}
                      register={register("is_soccer_pro_league", {required: true})}
                    />
                  </div>
                  <div>
                    <Radio
                      label="いいえ"
                      id="simulator_is_soccer_pro_league_false"
                      value="false"
                      defaultChecked={true}
                      register={register("is_soccer_pro_league", {required: true})}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-10">
                <label htmlFor="twitter_followers" className="block mb-1">
                  Xフォロワー数
                </label>
                <div className="before:top-[10px] after:content-['人'] after:top-[30px] before:content-[''] left-icon icon-x before:absolute after:absolute relative before:left-5 after:right-5 after:inline-block">
                  <input
                    type="text"
                    id="twitter_followers"
                    className="py-[10px] pl-[100px] h-[80px] block pr-10 w-full text-right text-xl bg-[#F4F6F8]"
                    {...register("twitter_followers", {
                      required: true,
                      pattern: /^[0-9]+$/,
                    })}
                  />
                </div>
                <div className="mt-1 text-[#FF0000] text-xs">
                  {errors?.twitter_followers?.type === "required" && (
                    <p className="before:content-['*']">必須項目です</p>
                  )}
                  {errors?.twitter_followers?.type === "pattern" && (
                    <p className="before:content-['*']">
                      半角数字で入力してください
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-10">
                <label htmlFor="instagram_followers" className="block mb-1">
                  Instagramフォロワー数
                </label>
                <div className="before:top-[10px] after:content-['人'] after:top-[30px] before:content-[''] left-icon icon-instagram before:absolute after:absolute relative before:left-5 after:right-5 after:inline-block">
                  <input
                    id="instagram_followers"
                    type="text"
                    className="py-[10px] pl-[100px] h-[80px] form-input block pr-10 w-full text-right text-xl bg-[#F4F6F8]"
                    {...register("instagram_followers", {
                      required: true,
                      pattern: /^[0-9]+$/,
                    })}
                  />
                </div>
                <div className="mt-1 text-[#FF0000] text-xs">
                  {errors?.instagram_followers?.type === "required" && (
                    <p className="before:content-['*']">必須項目です</p>
                  )}
                  {errors?.instagram_followers?.type === "pattern" && (
                    <p className="before:content-['*']">
                      半角数字で入力してください
                    </p>
                  )}
                </div>
              </div>
              <div>
                <label htmlFor="facebook_followers" className="block mb-1">
                  Facebookフォロワー数
                </label>
                <div className="before:top-[10px] after:content-['人'] after:top-[30px] before:content-[''] left-icon icon-facebook before:absolute after:absolute relative before:left-5 after:right-5 after:inline-block">
                  <input
                    id="facebook_followers"
                    type="text"
                    className="py-[10px] pl-[100px] h-[80px] form-input block pr-10 w-full text-right text-xl bg-[#F4F6F8]"
                    {...register("facebook_followers", {
                      required: true,
                      pattern: /^[0-9]+$/,
                    })}
                  />
                </div>
                <div className="mt-1 text-[#FF0000] text-xs">
                  {errors?.facebook_followers?.type === "required" && (
                    <p className="before:content-['*']">必須項目です</p>
                  )}
                  {errors?.facebook_followers?.type === "pattern" && (
                    <p className="before:content-['*']">
                      半角数字で入力してください
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-[60px]">
              <p className="mb-5 pl-2 sm:text-xl font-bold border-l-4 border-[#006AC9]">
                お客様情報
              </p>
              <div className="mb-10">
                <label htmlFor="" className="block mb-1">
                  会社/クラブ名
                </label>
                <input
                  type="text"
                  className="block p-2 w-full bg-[#F4F6F8]"
                  {...register("company_name", {
                    required: true,
                  })}
                />
                <div className="mt-1 text-[#FF0000] text-xs">
                  {errors?.company_name && (
                    <p className="before:content-['*']">必須項目です</p>
                  )}
                </div>
              </div>
              <div className="mb-10">
                <label htmlFor="" className="block mb-1">
                  ご担当者様氏名
                </label>
                <input
                  type="text"
                  className="block p-2 w-full bg-[#F4F6F8]"
                  {...register("name", {
                    required: true,
                  })}
                />
                <div className="mt-1 text-[#FF0000] text-xs">
                  {errors?.name && (
                    <p className="before:content-['*']">必須項目です</p>
                  )}
                </div>
              </div>
              <div className="mb-10">
                <label htmlFor="" className="block mb-1">
                  ご担当者様メールアドレス
                </label>
                <input
                  type="text"
                  className="block p-2 w-full bg-[#F4F6F8]"
                  {...register("email", {
                    required: true,
                    pattern:
                      /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/,
                  })}
                />
                <div className="mt-1 text-[#FF0000] text-xs">
                  {errors?.email?.type === "required" && (
                    <p className="before:content-['*']">必須項目です</p>
                  )}
                  {errors?.email?.type === "pattern" && (
                    <p className="before:content-['*']">
                      メールアドレスが正しくありません
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-5">
              <div className="flex items-center justify-center text-sm">
                <input
                  type="checkbox"
                  {...register("privacy", {
                    required: true
                  })}
                  id="privacy_policy"
                />
                <label className="ml-1" htmlFor="privacy_policy">
                  <a
                    href="https://corp.spportunity.com/privacy_policy/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="link-hover-none underline"
                  >
                    プライバシーポリシー
                  </a>
                  を確認・承諾しました。
                </label>
              </div>
              <div className="mt-1 text-[#FF0000] text-xs text-center">
                {errors?.privacy && (
                  <p className="before:content-['*']">必須項目です</p>
                )}
              </div>
            </div>
            <div className="sm:mx-auto h-[50px] sm:w-[360px]">
              <BaseButton
                text="シミュレーション実施"
                isSubmit={true}
              />
            </div>
          </form>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
